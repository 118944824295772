<template>
  <div class="pageContol">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;" @click="goHerf()">考试计划</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">新增课程</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items:flex-start">
          <div class="searchbox" style="margin-bottom:15px">
            <div title="课程名称" class="searchboxItem ci-full">
              <span class="itemLabel">课程名称:</span>
              <el-input
                v-model="courseName"
                type="text"
                size="small"
                placeholder="请输入课程名称"
                clearable
              />
            </div>
            <el-button
              class="bgc-bv"
              style="margin:0 10px"
              round
              @click="getData()"
              >查询</el-button
            >
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                fixed
                width="100px"
              />
              <el-table-column
                label="课程名称"
                align="left"
                prop="courseName"
                show-overflow-tooltip
                width="280"
                fixed
              />
             
              <el-table-column
                label="课件数量"
                align="right"
                prop="coursewareNum"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  {{ scope.row.coursewareNum || 0 }}
                </template>
              </el-table-column>
              <el-table-column
                label="操作"
                fixed="right"
                align="center"
                width="200px"
              >
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    style="padding:0px 5px"
                    size="mini"
                    @click="
                      handlEdit(
                        scope.row.courseId,
                      )
                    "
                    >详情</el-button
                  >
                  <el-button
                    type="text"
                    style="padding:0px 5px"
                    size="mini"
                    :disabled="scope.row.added"
                    @click="
                      handlAdd(
                        scope.row.courseId
                      )
                    "
                    >添加课程</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "questionbankList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      courseName: "", //课程名称
    };
  },
  created() {
  },
  computed: {},
  methods: {
    /* 获取列表数据 */
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
       planId:this.$route.query.planId,
      };
      if (this.courseName) {
        params.courseName = this.courseName;
      }
      this.doFetch(
        {
          url: "/biz/evaluate/plan/usable/course/page",
          params,
          pageNum,
        },
      );
    },
    /* 编辑 */
    handlEdit(courseId) {
      this.$router.push({
        path: "/web/appraisalInstitution/appraisalCourseDetail",
        query: {
          courseId,
        },
      });
    },
    handlAdd(courseId) {
        const parmar = {
            planId:this.$route.query.planId,
            courseId
        }
        this.$post('/biz/evaluate/plan/course/add',parmar).then(ret => {
            if(ret.status == 0) {
                this.$message({
                    message:'添加成功',
                    type:'success'
                })
                this.getData(-1)
            }
        }).catch(err => {
            return;
        })
    },
    goHerf() {
          this.$router.back();
        },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 2.55) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1 + 40;
      }
      if (page) {
        tHeight -= 25;
      }
      this.tableHeight = tHeight;
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: {
      handler: function(val, oldVal) {
        if (val.query.refresh) {
          this.getData(-1);
        } else {
           this.getData();
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style lang="less"></style>
